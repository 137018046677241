import styled from '@emotion/styled'
import i18n from 'i18next'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Cognisphere,
  ContentContainer,
  Heading,
  KeyVisual,
  LineContainer,
  Paragraph,
} from '../components'
import { PageFrame, PageParams } from '../components/organisms/pageFrame'
import { siemensThemeDarkHeader } from './../themes/siemens/siemensThemeDarkHeader'
import { mq } from './../utility/breakpoint'
import parse from 'html-react-parser'

const IndexPage = (params: PageParams) => {
  const {
    pageContext: { lang },
  } = params
  const [loaded, setLoaded] = React.useState(false)

  useEffect(() => {
    i18n.changeLanguage(lang)
    setLoaded(true)
  }, [])
  const { t } = useTranslation('forbidden')

  return (
    <PageFrame {...params} pageName="forbidden" isTop hideMenu={true}>
      {loaded && (
        <KeyVisual>
          <ContentContainer fullheight>
            <Cognisphere visual="/assets/keyvisual.mp4" />

            <StartTeaser fullheight width={50}>
              <div>
                <LineContainer className="LineContainer">
                  <Heading>{t('header')}</Heading>
                </LineContainer>
                <Paragraph>{parse(t('copy'))}</Paragraph>
                <Button onClick={() => window.open(t('cta.uri'))}>
                  {t('cta.label')}
                </Button>
              </div>
            </StartTeaser>
          </ContentContainer>
        </KeyVisual>
      )}
    </PageFrame>
  )
}

export default IndexPage

interface StartTeaserProps {
  fullheight?: boolean
  width?: number
}

const StartTeaser = styled.div<StartTeaserProps>(
  ({ theme: { grid }, fullheight, width }) => `
  height: 100%;
  ${[mq[3]]}{
    ${width && ` width: ${width}%;`}
  }
  ${
    fullheight &&
    `min-height:calc(100vh - 220px);
    margin-top:100px;
    ${[mq[2]]}{
      margin-top:0px;
      display:flex;
      align-items:center;
    }`
  }

  margin-left:${100 / grid.columns}vw;
  h1{
    margin:0;
  }
  p, .LineContainer{ margin-bottom:50px;}
`
)
